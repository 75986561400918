.mod-content {
  .mdc-form-field{
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0.4em 0.1em;
  }

  .mdc-form-field>label {
    color: var(--secondary-font);
    white-space: normal;
    line-height: normal !important;
    display: inline-flex !important;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
}


.mdc-radio__inner-circle {
  border-color: var(--radio-checkbox-filler) !important;
}

.mdc-radio__outer-circle {
  border-color: var(--radio-checkbox-border)!important;
}

.mdc-checkbox__background {
  border: 2px solid var(--radio-checkbox-border)!important;
  border-radius: 5px !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
  background-color: var(--radio-checkbox-filler) !important;
  background-clip: content-box;
  border: 2px solid var(--radio-checkbox-border)!important;
  border-radius: 5px !important;
  padding: 2px;

  svg {
    display: none;
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  background-color: var(--primary-background) !important;
}

.mdc-text-field {
  overflow: unset !important;
  padding: 0 !important;
}


mat-radio-group {
  display: flex;
  flex-direction: column;
}



.custom-snackbar {
  background-color: var(--confirm-button-background);
  color: var(--confirm-button-text);
  border-radius: 5px;

  button {
    color: var(--primary-background)   !important;
    font-weight: 900;
  }
}

.mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--primary-font)  !important;
}

.mdc-linear-progress__bar-inner {
  border-color: var(--primary-font)  !important;
}

.mat-mdc-select-panel  {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  -webkit-box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  -moz-box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
}

mat-datepicker-content {
  background-color: #FFFFFF;
}

.mat-mdc-icon-button:disabled {
  color: unset !important;
  opacity: 0.4 !important;
}

.mat-calendar-body-disabled {
  opacity: 0.4;
}

.mat-calendar-body-selected {
  background-color:rgba(105,105,105,0.6);
}
