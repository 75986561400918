@use '@angular/material' as mat;

// Import Material Icons
@import url('//fonts.googleapis.com/icon?family=Material+Icons');

// Define color palette
$primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$warn: mat.m2-define-palette(mat.$m2-red-palette);
$theme: mat.m2-define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  )
));

$typography: mat.m2-define-typography-config(
  $button: mat.m2-define-typography-level(14px, 20px, 500) 
);

// Include core Material styles
@include mat.elevation-classes();
@include mat.app-background();

@include mat.all-component-themes($theme);
@include mat.all-component-typographies($typography);
