// TABLET
@media screen and (min-width: 700px ) {
  .supersections-grid-item {
    max-width: 30vw;
    .ss-image-holder {
      max-height: 30vw;
    }
  }

  .ss-grid-logo-container {
    height: 25%;
  }

  .image-holder {
    width: 280px;
  }

  .mobile {
    display: none !important;
  }
  .splash-screen-icon {
    display: none;
  }

  // Item cards:
  .with-image {
    height: 280px;
  }

  .item {
    padding: 10px 7px 10px 30px;
    .description {
      font-size: 20px;
    }

    .left {
      .name {
        font-size: 24px;
      }
    }

    .right {
      line-height: 40px;
    }

    .price-calories {
      margin-top: 20px;
    }

    .price {
      font-size: 21px;
    }

    .calories {
      font-size: 22px;
    }
  }


  :host-context(html[class="rtl"]) {
    .item {
      .description {
        line-height: 24px;
      }
    }
  }

  :host-context(html[class="ltr"]) {
    .item {
      .description {
        line-height: 22px;
      }
    }
  }

  .modifier-image-holder {
    img {
      max-height: 475px;
    }
  }

  .modifier-description {
    font-size: 28px;
  }

  .modifier-title {
    font-size: 26px;
  }

  .mod-name, .mod-price, .modifiers-total-price {
    font-size: 24px;
  }

  .required-label {
    font-size: 22px;
  }

  .modifiers-buttons {
    height: 200px;

    .confirm {
      font-size: 24px;
      height: 75px;
    }
  }

  .quantity-picker {
    height: 75px;
    .number {
      font-size: 32px;
    }
    .symbol {
      font-size: 32px;
    }
  }
}

// PHONE:
@media screen and (max-width: 700px ) {
  .image-holder {
    width: 140px;
  }

  .ss-grid-logo-container {
    height: 15%;
  }

  .desktop {
    display: none !important;
  }

  // Item cards:
  .with-image {
    height: 140px;
  }



  .item {
    padding: 10px 7px;
    .description {
      font-size: 10px;
    }

    .left {
      .name {
        font-size: 13px;
      }
    }

    .right {
      line-height: 20px;
    }

    .price {
      font-size: 11px;
    }

    .calories {
      font-size: 12px;
    }
  }

  :host-context(html[class="rtl"]) {
    .item {
      .description {
        line-height: 14px;
      }
    }
  }

  :host-context(html[class="ltr"]) {
    .item {
      .description {
        line-height: 12px;
      }
    }
  }

  .modifier-image-holder {
    img {
      max-height: 185px;
    }
  }

  .modifier-description, .modifiers-total-price {
    font-size: 15px;
  }

  .modifier-title {
    font-size: 16px;
  }

  .mod-name, .mod-price, .modifiers-total-price {
    font-size: 15px;
  }

  .required-label {
    font-size: 12px;
  }

  .modifiers-buttons {
    height: 125px;

    .confirm {
      font-size: 14px;
      height: 50px;
    }
  }

  .quantity-picker {
    height: 50px;
    .number {
      font-size: 16px;
    }
    .symbol {
      font-size: 22px;
    }
  }
}
